<header id="topnav" class="defaultscroll sticky active">
    <div class="container">
        <div>
            <a routerlink="/index"
               class="logo ng-star-inserted"
               href="/index"><img
                    src="assets/metro/logo/logo-metro-red.png.png"
                    height="24" alt="" class="l-dark logo-light-mode"><img
                    src="assets/images/logo-light.png" height="24" alt=""
                    class="logo-dark-mode"></a>
            <div class="menu-extras">
                <div class="menu-item"><a id="isToggle"
                                          class="navbar-toggle">
                    <div class="lines"><span></span><span
                    ></span><span></span></div>
                </a></div>
            </div>
        </div>
        <ul class="buy-button list-inline mb-0 ng-star-inserted">
            <li class="list-inline-item mb-0"><a

                    data-bs-toggle="offcanvas"
                    data-bs-target="#offcanvasRight"
                    aria-controls="offcanvasRight">
                <div id="settingbtn1" class="btn btn-icon btn-pills btn-soft-primary">
                    <i-feather name="settings" class="fea icon-sm"
                               _nghost-ng-c3061231048="" ng-reflect-name="settings">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="feather feather-settings">
                            <circle cx="12" cy="12" r="3"></circle>
                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                        </svg>
                    </i-feather>
                </div>
            </a></li>
            <li class="list-inline-item ps-2 mb-0"><a
                    href="https://1.envato.market/landrick"
                    target="_blank">
                <div id="settingbtn" class="btn btn-icon btn-pills btn-primary">
                    <i-feather name="shopping-cart" class="fea icon-sm"
                               _nghost-ng-c3061231048="" ng-reflect-name="shopping-cart">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                             class="feather feather-shopping-cart">
                            <circle cx="9" cy="21" r="1"></circle>
                            <circle cx="20" cy="21" r="1"></circle>
                            <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                        </svg>
                    </i-feather>
                </div>
            </a></li>
        </ul>
        <div id="navigation" class="ng-star-inserted active" style="display: none;">
            <ul class="navigation-menu active">
                <li class="active">
                    <a class="nav-link-ref active">Home</a></li>
                <li class="has-submenu">
                    <a>Pages</a>
                    <span class="menu-arrow"></span>
                    <ul class="submenu">
                        <li class="has-submenu">
                            <a> Company </a><span class="submenu-arrow"></span>
                            <ul class="submenu">
                                <li><a class="nav-link-ref" href="/page-aboutus"> About Us</a></li>
                            </ul>
                        </li>
                        <li class="has-submenu parent-menu-item">
                            <a>Multi Level Menu</a>
                            <span class="submenu-arrow"></span>
                            <ul class="submenu">
                                <li><a class="sub-menu-item">Level 1.0</a></li>
                                <li class="has-submenu parent-menu-item">
                                    <a>Level 2.0 </a>
                                    <span class="submenu-arrow"></span>
                                    <ul class="submenu">
                                        <li><a class="sub-menu-item">Level 2.1</a></li>
                                        <li><a class="sub-menu-item">Level 2.2</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
            <div class="buy-menu-btn d-none">
                <a target="_blank" class="btn btn-primary">Buy Now</a>
            </div>
        </div>
    </div>

</header>

