<!-- TAGLINE START-->

<div *ngIf="false" class="tagline bg-light">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="text-slider">
          <ul class="list-inline mb-0 move-text">
            <li class="list-inline-item px-2 mb-0"><b>Nuevas publicaciones</b> 50 <span class="text-success">+$ 4.62 ( +9.01%
                )</span></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TAGLINE END-->

<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass"
  [ngClass]="{'tagline-height': sliderTopbar == true}">
  <div class="container">
    <!-- Logo container-->
    <div >
      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>
      <ul class="buy-button list-inline mb-0">
        <li class="list-inline-item mb-0 ms-1">
          <div class="dropdown">
            <button type="button" class="btn btn-link text-decoration-none dropdown-toggle p-0 pe-2"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="uil uil-search text-muted"></i>
            </button>
            <div class="dropdown-menu dd-menu dropdown-menu-end bg-white shadow rounded border-0 mt-3 py-0"
              style="width: 300px;">
              <form>
                <input type="text" id="text" name="name" class="form-control border bg-white" placeholder="Search...">
              </form>
            </div>
          </div>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-facebook-f icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-github icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-twitter icons"></i></a>
        </li>
        <li class="list-inline-item mb-0 ms-1">
          <a href="javascript:void(0)" class="btn btn-icon btn-primary"><i class="uil uil-linkedin-alt icons"></i></a>
        </li>
      </ul>
    </div>
    <!--Login button Start-->
    <ul *ngIf="false" class="buy-button list-inline mb-0">
      <li class="list-inline-item mb-0">
        <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight" (click)="openright(content)">
          <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn"><i-feather name="settings"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>

      <li class="list-inline-item ps-2 mb-0">
        <a href="https://1.envato.market/landrick" target="_blank">
          <div class="btn btn-icon btn-pills btn-primary" id="settingbtn2">
            <i-feather name="shopping-cart"
              class="fea icon-sm"></i-feather></div>
        </a>
      </li>
    </ul>
    <div *ngIf="false" id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu" [class]="navClass">
        <li><a routerLink="/" class="nav-link-ref">Home</a></li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Landing</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-book-open fs-6 align-middle"></i> Landing Pages</li>
                <li><a class="nav-link-ref" routerLink="/index-saas">Saas</a></li>
                <li><a class="nav-link-ref" routerLink="/index-classic-saas">Classic Saas</a></li>
                <li><a class="nav-link-ref" routerLink="/index-agency">Agency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-apps">Application</a></li>
                <li><a class="nav-link-ref" routerLink="/index-classic-app">Classic Application</a></li>
                <li><a class="nav-link-ref" routerLink="/index-studio">Studio</a></li>
                <li><a class="nav-link-ref" routerLink="/index-marketing">Marketing</a></li>
                <li><a class="nav-link-ref" routerLink="/index-enterprise">Enterprise</a></li>
                <li><a class="nav-link-ref" routerLink="/index-services">Service</a></li>
                <li><a class="nav-link-ref" routerLink="/index-payments">Payments</a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution">IT Solution </a></li>
                <li><a class="nav-link-ref" routerLink="/index-it-solution-two">IT Solution Two</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-book-open fs-6 align-middle"></i> Landing Pages</li>
                <li><a class="nav-link-ref" routerLink="/index-developer">Developer</a></li>
                <li><a class="nav-link-ref" routerLink="/index-seo-agency">SEO Agency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-hospital">Hospital</a></li>
                <li><a class="nav-link-ref" routerLink="/index-coworking">Coworking</a></li>
                <li><a class="nav-link-ref" routerLink="/index-business">Business</a></li>
                <li><a class="nav-link-ref" routerLink="/index-modern-business">Modern Business</a></li>
                <li><a class="nav-link-ref" routerLink="/index-finance">Finance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-logistics">Delivery & Logistics </a></li>
                <li><a class="nav-link-ref" routerLink="/index-social-marketing">Social Media</a></li>
                <li><a class="nav-link-ref" routerLink="/index-digital-agency">Digital Agency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-customer">Customer</a></li>
                <li><a class="nav-link-ref" routerLink="/index-software">Software</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-book-open fs-6 align-middle"></i> Landing Pages</li>
                <li><a class="nav-link-ref" routerLink="/index-hotel">Hotel</a></li>
                <li><a class="nav-link-ref" routerLink="/index-construction">Construction</a></li>
                <li><a class="nav-link-ref" routerLink="/index-real-estate">Real Estate</a></li>
                <li><a class="nav-link-ref" routerLink="/index-videocall">Video Conference</a></li>
                <li><a class="nav-link-ref" routerLink="/index-blockchain">Blockchain</a></li>
                <li><a class="nav-link-ref" routerLink="/index-crypto-two">Cryptocurrency Two</a></li>
                <li><a class="nav-link-ref" routerLink="/index-integration">Integration</a></li>
                <li><a class="nav-link-ref" routerLink="/index-task-management">Task Management</a></li>
                <li><a class="nav-link-ref" routerLink="/index-email-inbox">Email Inbox </a></li>
                <li><a class="nav-link-ref" routerLink="/index-travel">Travel </a></li>
                <li><a class="nav-link-ref" routerLink="/index-course">Course</a></li>
                <li><a class="nav-link-ref" routerLink="/index-online-learning">Online Learning</a></li>
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-book-open fs-6 align-middle"></i> Landing Pages</li>
                <li><a class="nav-link-ref" routerLink="/index-insurance">Insurance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-single-product">Product</a></li>
                <li><a class="nav-link-ref" routerLink="/index-car-riding">Car Ride</a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-one">Landing One</a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-two">Landing Two </a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-three">Landing Three</a></li>
                <li><a class="nav-link-ref" routerLink="/index-landing-four">Landing Four</a></li>
                <li><a class="nav-link-ref" routerLink="/index-personal">Personal</a></li>
                <li><a class="nav-link-ref" routerLink="/index-freelancer">Freelance</a></li>
                <li><a class="nav-link-ref" routerLink="/index-event">Event</a></li>
                <li><a class="nav-link-ref" routerLink="/index-ebook">E-Book</a></li>
                <li><a class="nav-link-ref" routerLink="/index-onepage">Saas <span
                      class="badge badge-pill bg-warning ms-2">Onepage</span></a>
              </ul>
            </li>
            <li>
              <ul>
                <li class="megamenu-head"><i class="uil uil-cube fs-6 align-middle"></i> Full Demos</li>
                <li><a class="nav-link-ref" routerLink="/index-corporate">Corporate Business</a></li>
                <li><a class="nav-link-ref" routerLink="/index-crypto">Cryptocurrency</a></li>
                <li><a class="nav-link-ref" routerLink="/index-shop">Shop</a></li>
                <li><a class="nav-link-ref" routerLink="/index-portfolio">Portfolio</a></li>
                <li><a class="nav-link-ref" routerLink="/helpcenter-overview">Helpcenter</a></li>
                <li><a class="nav-link-ref" routerLink="/index-hosting">Hosting & Domain</a></li>
                <li><a class="nav-link-ref" routerLink="/index-job">Job</a></li>
                <li><a class="nav-link-ref" routerLink="/forums">Forums</a></li>
                <li><a class="nav-link-ref" routerLink="/index-blog">Blog </a></li>
                <li><a class="nav-link-ref" routerLink="/index-nft">NFT Marketplace </a></li>
              </ul>
            </li>
          </ul>
        </li>

        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Pages</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Company </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-aboutus"> About Us</a></li>
                <li><a class="nav-link-ref" routerLink="/page-aboutus-two"> About Us Two </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/page-services">Services</a></li>
                <li><a class="nav-link-ref" routerLink="/page-history">History </a></li>
                <li><a class="nav-link-ref" routerLink="/page-team"> Team</a></li>
                <li><a class="nav-link-ref" routerLink="/page-pricing">Pricing</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Account </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/account-profile">Profile</a></li>
                <li><a class="nav-link-ref" routerLink="/account-members">Members </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/account-works">Works </a></li>
                <li><a class="nav-link-ref" routerLink="/account-messages">Messages </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/account-chat">chat </a></li>
                <li><a class="nav-link-ref" routerLink="/account-payments">Payments </a>
                </li>
                <li><a class="nav-link-ref" routerLink="/account-setting">Setting</a></li>
                <li><a class="nav-link-ref" routerLink="/page-invoice">Invoice</a></li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Email
                Template</a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a routerLink="/email-confirmation" class="sub-menu-item">Confirmation</a></li>
                <li><a routerLink="/email-password-reset" class="sub-menu-item">Reset Password</a></li>
                <li><a routerLink="/email-alert" class="sub-menu-item">Alert</a></li>
                <li><a routerLink="/email-invoice" class="sub-menu-item">Invoice</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Blog </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-blog-grid">Blog Grid</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-sidebar">Blog with Sidebar</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-list">Blog Listing</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-list-sidebar">Blog List & Sidebar</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-detail">Blog Detail</a></li>
                <li><a class="nav-link-ref" routerLink="/page-blog-detail-two">Blog Detail 2 </a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Case Study </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-cases">All Cases </a></li>
                <li><a class="nav-link-ref" routerLink="/page-case-detail">Case Detail </a></li>
              </ul>
            </li>
            <li><a href="/course-detail" class="nav-link-ref">Course Detail </a></li>
            <li class="has-submenu parent-menu-item"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Auth
                Pages </a>
              <span class="submenu-arrow"></span>
              <ul class="submenu">
                <li class="has-submenu parent-menu-item"><a href="javascript:void(0)"> Login </a>
                  <ul class="submenu">
                    <li><a routerLink="/auth-login" class="sub-menu-item">Login</a></li>
                    <li><a routerLink="/auth-cover-login" class="sub-menu-item">Login Cover</a></li>
                    <li><a routerLink="/auth-login-three" class="sub-menu-item">Login Simple</a></li>
                    <li><a routerLink="/auth-bs-login" class="sub-menu-item">BS5 Login</a></li>
                    <li><a routerLink="/auth-login-bg-video" class="sub-menu-item">Login Five </a></li>
                  </ul>
                </li>

                <li class="has-submenu parent-menu-item"><a routerLink="/auth-signup"> Signup </a>
                  <ul class="submenu">
                    <li><a routerLink="/auth-signup" class="sub-menu-item">Signup</a></li>
                    <li><a routerLink="/auth-cover-signup" class="sub-menu-item">Signup Cover</a></li>
                    <li><a routerLink="/auth-signup-three" class="sub-menu-item">Signup Simple</a></li>
                    <li><a routerLink="/auth-bs-signup" class="sub-menu-item">BS5 Singup</a></li>
                    <li><a routerLink="/auth-signup-bg-video" class="sub-menu-item">Singup Five </a></li>
                  </ul>
                </li>

                <li class="has-submenu parent-menu-item"><a href="javascript:void(0)"> Reset password </a>
                  <ul class="submenu">
                    <li><a routerLink="/auth-re-password" class="sub-menu-item">Reset Password</a></li>
                    <li><a routerLink="/auth-cover-re-password" class="sub-menu-item">Reset Password Cover</a></li>
                    <li><a routerLink="/auth-re-password-three" class="sub-menu-item">Reset Password Simple</a></li>
                    <li><a routerLink="/auth-bs-reset" class="sub-menu-item">BS5 Reset Password</a></li>
                    <li><a routerLink="/auth-reset-password-bg-video" class="sub-menu-item">Reset Pass Five </a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Utility </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-terms">Terms of Services</a></li>
                <li><a class="nav-link-ref" routerLink="/page-privacy">Privacy Policy</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Special </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-comingsoon">Coming Soon</a></li>
                <li><a class="nav-link-ref" routerLink="/page-comingsoon2">Coming Soon Two</a></li>
                <li><a class="nav-link-ref" routerLink="/page-maintenance">Maintenance</a></li>
                <li><a class="nav-link-ref" routerLink="/page-error">Error</a></li>
                <li><a class="nav-link-ref" routerLink="/page-thankyou">Thank you</a></li>
              </ul>
            </li>
            <li class="has-submenu"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Contact </a><span
                class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a class="nav-link-ref" routerLink="/page-contact-detail">Contact Detail</a></li>
                <li><a class="nav-link-ref" routerLink="/page-contact-one">Contact One</a></li>
                <li><a class="nav-link-ref" routerLink="/page-contact-two">Contact Two</a></li>
                <li><a class="nav-link-ref" routerLink="/page-contact-three">Contact Three</a></li>
              </ul>
            </li>
            <li class="has-submenu parent-menu-item"><a href="javascript:void(0)" (click)="onMenuClick($event)"> Multi
                Level Menu</a><span class="submenu-arrow"></span>
              <ul class="submenu">
                <li><a href="javascript:void(0)" class="sub-menu-item">Level 1.0</a></li>
                <li class="has-submenu parent-menu-item"><a href="javascript:void(0)"> Level 2.0 </a><span
                    class="submenu-arrow"></span>
                  <ul class="submenu">
                    <li><a href="javascript:void(0)" class="sub-menu-item">Level 2.1</a></li>
                    <li><a href="javascript:void(0)" class="sub-menu-item">Level 2.2</a></li>
                  </ul>
                </li>
              </ul>
            </li>
            <li><a routerLink="/page-footer" class="sub-menu-item">Footer Layouts </a></li>
          </ul>
        </li>

        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Demos</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li>
                  <a routerLink="/index-corporate" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/corporate.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Corporate</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a routerLink="/index-crypto" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/crypto.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Cryptocurrency</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a routerLink="/index-shop" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/shop.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Shop</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a routerLink="/index-portfolio" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/portfolio.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Portfolio</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a routerLink="/helpcenter-overview" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/help-center.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Help Center</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a routerLink="/index-hosting" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/hosting.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Hosting & Domain</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a routerLink="/index-job" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/job.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Job & Career</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a routerLink="/forums" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/forums.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Forums</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>

            <li>
              <ul>
                <li>
                  <a routerLink="/index-blog" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/blog.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">Blog</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a routerLink="index-nft" class="sub-menu-item">
                    <div class="text-lg-center">
                      <span class="d-none d-lg-block"><img src="assets/images/demos/nft.png"
                          class="img-fluid rounded shadow-md" alt=""></span>
                      <span class="mt-lg-2 d-block">NFT Marketplace</span>
                    </div>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </li>

        <li class="has-submenu parent-parent-menu-item">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Components</a><span class="menu-arrow"></span>
          <ul class="submenu megamenu">
            <li>
              <ul>
                <li><a routerLink="/ui-button" class="nav-link-ref"><i class="uil uil-cube fs-6 align-middle me-1"></i>
                    Buttons</a></li>
                <li><a routerLink="/ui-badges" class="nav-link-ref"><i class="uil uil-award fs-6 align-middle me-1"></i>
                    Badges</a></li>
                <li><a routerLink="/ui-alert" class="nav-link-ref"><i
                      class="uil uil-info-circle fs-6 align-middle me-1"></i> Alert</a></li>
                <li><a routerLink="/ui-dropdown" class="nav-link-ref"><i
                      class="uil uil-layers fs-6 align-middle me-1"></i> Dropdowns</a></li>
                <li><a routerLink="/ui-typography" class="nav-link-ref"><i
                      class="uil uil-align-center-alt fs-6 align-middle me-1"></i> Typography</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="/ui-background" class="nav-link-ref"><i
                      class="uil uil-palette fs-6 align-middle me-1"></i> Background</a></li>
                <li><a routerLink="/ui-text" class="nav-link-ref"><i class="uil uil-text fs-6 align-middle me-1"></i>
                    Text Color</a></li>
                <li><a routerLink="/ui-accordion" class="nav-link-ref"><i
                      class="uil uil-list-ui-alt fs-6 align-middle me-1"></i> Accordions</a></li>
                <li><a routerLink="/ui-card" class="nav-link-ref"><i
                      class="uil uil-postcard fs-6 align-middle me-1"></i>
                    Cards</a></li>
                <li><a routerLink="/ui-tooltip-popover" class="nav-link-ref"><i
                      class="uil uil-backspace fs-6 align-middle me-1"></i> Tooltips & Popovers</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="/ui-shadow" class="nav-link-ref"><i
                      class="uil uil-square-full fs-6 align-middle me-1"></i> Shadows</a></li>
                <li><a routerLink="/ui-border" class="nav-link-ref"><i
                      class="uil uil-border-out fs-6 align-middle me-1"></i> Border</a></li>
                <li><a routerLink="/ui-carousel" class="nav-link-ref"><i
                      class="uil uil-slider-h-range fs-6 align-middle me-1"></i> Carousel</a></li>
                <li><a routerLink="/ui-form" class="nav-link-ref"><i class="uil uil-notes fs-6 align-middle me-1"></i>
                    Form Elements</a></li>
                <li><a routerLink="/ui-breadcrumb" class="nav-link-ref"><i
                      class="uil uil-sort-amount-down fs-6 align-middle me-1"></i> Breadcrumb</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="ui-pagination" class="nav-link-ref"><i
                      class="uil uil-copy fs-6 align-middle me-1"></i> Pagination</a></li>
                <li><a routerLink="/ui-avatar" class="nav-link-ref"><i class="uil uil-image fs-6 align-middle me-1"></i>
                    Avatars</a></li>
                <li><a routerLink="/ui-nav-tabs" class="nav-link-ref"><i
                      class="uil uil-bars fs-6 align-middle me-1"></i>
                    Nav Tabs</a></li>
                <li><a routerLink="/ui-modals" class="nav-link-ref"><i
                      class="uil uil-vector-square fs-6 align-middle me-1"></i> Modals</a></li>
              </ul>
            </li>

            <li>
              <ul>
                <li><a routerLink="/ui-tables" class="nav-link-ref"><i class="uil uil-table fs-6 align-middle me-1"></i>
                    Tables</a></li>
                <li><a routerLink="/ui-icons" class="nav-link-ref"><i class="uil uil-icons fs-6 align-middle me-1"></i>
                    Icons</a></li>
                <li><a routerLink="/ui-progressbar" class="nav-link-ref"><i
                      class="uil uil-brackets-curly fs-6 align-middle me-1"></i> Progressbar</a></li>
                <li><a routerLink="/ui-lightbox" class="nav-link-ref"><i
                      class="uil uil-play-circle fs-6 align-middle me-1"></i> Lightbox</a></li>
              </ul>
            </li>
          </ul>
        </li>
        <li class="has-submenu">
          <a href="javascript:void(0)" (click)="onMenuClick($event)">Docs</a><span class="menu-arrow"></span>
          <ul class="submenu">
            <li><a class="nav-link-ref" routerLink="/documentation">Documentation</a></li>
            <li><a class="nav-link-ref" routerLink="/changelog">Changelog</a></li>
            <li><a class="nav-link-ref" routerLink="/widget">Widget</a></li>
          </ul>
        </li>
      </ul>
      <!--end navigation menu-->
      <div class="buy-menu-btn d-none">
        <a href="javascript: void(0);" target="_blank" class="btn btn-primary">Buy Now</a>
      </div>
      <!--end login button-->
      <!--end login button-->
    </div>
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->

<!-- Offcanvas Start -->
<ng-template #content let-offcanvas>
  <div class="offcanvas-header p-4 border-bottom">
    <h5 id="offcanvasRightLabel" class="mb-0">
      <img src="assets/metro/logo/logo-metro-red.png.png" height="24" class="light-version" alt="">
      <img src="assets/images/logo-light.png" height="24" class="dark-version" alt="">
    </h5>
    <button type="button" class="btn-close d-flex align-items-center text-dark" data-bs-dismiss="offcanvas"
      aria-label="Close" (click)="offcanvas.dismiss('Cross click')"><i class="uil uil-times fs-4"></i></button>
  </div>
  <div class="offcanvas-body p-4">
    <div class="row">
      <div class="col-12">
        <img src="assets/images/contact.svg" class="img-fluid d-block mx-auto" style="max-width: 256px;" alt="">
        <div class="card border-0 mt-5" style="z-index: 1">
          <div class="card-body p-0">
            <form method="post" name="myForm" id="myForm" onsubmit="return validateForm()">
              <p id="error-msg" class="mb-0"></p>
              <div id="simple-msg"></div>
              <div class="row">
                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Your Name <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input name="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="mb-3">
                    <label class="form-label">Your Email <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input name="email" id="email" type="email" class="form-control ps-5" placeholder="Email :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Subject</label>
                    <div class="form-icon position-relative">
                      <i-feather name="book" class="fea icon-sm icons"></i-feather>
                      <input name="subject" id="subject" class="form-control ps-5" placeholder="subject :">
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="mb-3">
                    <label class="form-label">Comments <span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                      <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                        placeholder="Message :"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-grid">
                    <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="offcanvas-footer p-4 border-top text-center">
    <ul class="list-unstyled social-icon social mb-0 d-flex justify-content-center gap-1">
      <li class="list-inline-item mb-0"><a href="https://1.envato.market/landrick" target="_blank" class="rounded"><i
            class="uil uil-shopping-cart align-middle" title="Buy Now"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://dribbble.com/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-dribbble align-middle" title="dribbble"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.behance.net/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-behance align-middle" title="behance"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.facebook.com/shreethemes" target="_blank"
          class="rounded"><i class="uil uil-facebook-f align-middle" title="facebook"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://www.instagram.com/shreethemes/" target="_blank"
          class="rounded"><i class="uil uil-instagram align-middle" title="instagram"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://twitter.com/shreethemes" target="_blank" class="rounded"><i
            class="uil uil-twitter align-middle" title="twitter"></i></a></li>
      <li class="list-inline-item mb-0"><a href="mailto:support@shreethemes.in" class="rounded"><i
            class="uil uil-envelope align-middle" title="email"></i></a></li>
      <li class="list-inline-item mb-0"><a href="https://shreethemes.in" target="_blank" class="rounded"><i
            class="uil uil-globe align-middle" title="website"></i></a></li>
    </ul><!--end icon-->
  </div>
</ng-template>
<!-- Offcanvas End -->