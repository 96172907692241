import { Component } from '@angular/core';

@Component({
  selector: 'app-header-metro',
  templateUrl: './header-metro.component.html',
  styleUrl: './header-metro.component.css'
})
export class HeaderMetroComponent {

}
